<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7">
            <h6 class="h2 text-white d-inline-block mb-0">Rincian Pendaftaran SPARING  <span class="text-yellow">{{ listData.company_name }}</span></h6>
          </b-col>
          <b-col lg="6" cols="7" style="display:flex;justify-content:flex-end">
            <base-button type="secondary" @click="seeRiwayatValidasi()">Riwayat Validasi</base-button>
            <base-button type="secondary" @click="seeTte()" v-if="listData.status == 'Diterima'">Lembar TTE</base-button>
            <base-button  @click="back()">Kembali</base-button>
          </b-col>
        </b-row>
      </base-header>

      <b-container v-if="isLoading">
        <h2 class="text-center" style="margin-top: 30vh;">Loading...</h2>
      </b-container>
  
      <b-container fluid class="mt--5" v-if="!isLoading">
        <b-row>
          <!-- umum -->
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <h6 class="surtitle">Rincian</h6>
                <h5 class="h3 mb-0">Titik Penaatan</h5>
  
                <div class="card-body">

                  <ul class="list-group">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Nama Industri</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp_name }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jenis Industri</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.comp_type_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Provinsi</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.province_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Kabupaten / Kota</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.kabkot_name}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Surat Izin</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.permit_no }}</span>
                        </div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col-lg-3">
                          <strong>Jumlah Titik Penaatan</strong>
                        </div>
                        <div class="col-lg-9">
                          <span>{{ listData.total_station }}</span>
                        </div>
                      </div>
                    </li>

                    
                  </ul>
                </div>
              </template>
            </b-card>
          </b-col>

          <!-- titik penaatan -->
          <b-col xl="12">
            <b-card  >
              <template v-slot:header>
                <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Rincian Titik Penaatan</h5>
  
                <div class="card-body" v-for="(item,idx) in listData.station" :key="idx">
                  <div class="row">
                    <div class="col-lg-12">
                      <div @click="toggleAccordion('Logger', item.id, idx)" class="accordion-header" style="cursor:pointer">
                        <button class="btn btn-primary">Lihat Titik Penaatan {{idx + 1}}</button>
                      </div>
                    </div>
                    <transition name="slide">
                      <div class="col-lg-12 mt-3" v-if="isOpenAccordion(idx)">
                        <div>
                            <!-- identifikasi sumber pencemar -->
                            <ul class="list-group mb-5 myshadow">
                              <li class="list-group-item bg-dark text-white" >
                                <div class="row">
                                  <div class="col-lg-12">
                                    <strong>Identifikasi Sumber Pencemar</strong>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Nama Titik Penaatan</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.spot_name}}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Sumber air limbah</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.waste_source}}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Lintang</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.latitude }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Bujur</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.longitude }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Nama Badan Penerima Air Limbah</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.waste_receiver }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>DAS</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.das }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Jenis teknologi pengolahan air limbah</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.processing_technique }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Jenis air limbah</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.waste_type }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Debit</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.debit_value}} {{ item.debit_unit }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Kapasitas produksi sesuai izin</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.capacity_permit }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Kapasitas produksi senyatanya</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.capacity_value }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Frekuensi pembuangan air limbah</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.frequency.type }}</span>
                                  </div>
                                </div>
                              </li>

                              <!-- Persyaratan Teknis -->
                              <li class="list-group-item bg-dark text-white mt-5" >
                                <div class="row">
                                  <div class="col-lg-12">
                                    <strong>Persyaratan Teknis</strong>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Frekuensi Per Hari</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.frequency.hari }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Frekuensi Per Minggu</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.frequency.jam }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Frekuensi Per Tahun</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.frequency.bulan }}</span>
                                  </div>
                                </div>
                              </li>
                            
                              <!-- Optional  -->
                              <div v-if="item.frequency.type == 'Tidak Rutin/Intermiten'">
                                <li class="list-group-item">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Latar Belakang</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span v-html="item.frequency.latar_belakang"></span>
                                    </div>
                                  </div>
                                </li>
            
                                <li class="list-group-item">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Frekuensi detail IPAL Engineering</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span v-html="item.frequency.detail_eng_ipal"></span>
                                    </div>
                                  </div>
                                </li>
            
                                <li class="list-group-item">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Frekuensi Foto IPAL Engineering</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <a :href="getBlobsFile(item.frequency.ipal_file_id)" target="blank">
                                          <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                          <span>{{
                                            item.frequency.ipal_file_id === "" ||
                                            item.frequency.ipal_file_id === undefined ||
                                            item.frequency.ipal_file_id === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>
                                          
                                      </a>
                                    </div>
                                  </div>
                                </li>
            
                              
            
                                <li class="list-group-item">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Frekuensi metode pengukuran debit</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span v-html="item.frequency.metode_ukur"></span>
                                    </div>
                                  </div>
                                </li>
            
                                <li class="list-group-item">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Frekuensi Logbook path</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <a :href="getBlobsFile(item.frequency.logbook_file_id)" target="blank">
                                        <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                          <span>{{
                                            item.frequency.logbook_file_id === "" ||
                                            item.frequency.logbook_file_id === undefined ||
                                            item.frequency.logbook_file_id === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>
                                        
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </div>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Lulus uji Konektivitas</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.tech_requirement.tipe_uji }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" v-if="item.tech_requirement.tipe_uji == 'Individu'">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Nomor surat lulus uji konektifitas</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.tech_requirement.no_surat }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Surat keterangan lulus uji konektivitas </strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.lulus_uji_file_id)" target="blank">
                                        <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                            item.tech_requirement.lulus_uji_file_id === "" ||
                                            item.tech_requirement.lulus_uji_file_id === undefined ||
                                            item.tech_requirement.lulus_uji_file_id === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" v-if="item.tech_requirement.tipe_uji == 'Penyedia Barang dan Jasa Alat Sparing'">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Nama Penyedia Barang dan Jasa</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.tech_requirement.nama_penyedia }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" v-if="item.tech_requirement.tipe_uji == 'Penyedia Barang dan jasa Alat Sparing'">
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Kontrak Kerja Sama</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.kontak_kerjasama_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.kontak_kerjasama_file_id === "" ||
                                          item.tech_requirement.kontak_kerjasama_file_id === undefined ||
                                          item.tech_requirement.kontak_kerjasama_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>   
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Info Alat</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.tech_requirement.info_alat}}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Hasil kalibrasi saat awal pemasangan alat</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.kalibrasi_awal_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.kalibrasi_awal_file_id === "" ||
                                          item.tech_requirement.kalibrasi_awal_file_id === undefined ||
                                          item.tech_requirement.kalibrasi_awal_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>
                                      
                                    </a>
                                  </div>
                                  
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Hasil kalibrasi alat rutin</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.kalibrasi_rutin_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.kalibrasi_rutin_file_id === "" ||
                                          item.tech_requirement.kalibrasi_rutin_file_id === undefined ||
                                          item.tech_requirement.kalibrasi_rutin_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>
                                      
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Hasil uji kinerja dari labotarium </strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.kinerja_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.kinerja_file_id === "" ||
                                          item.tech_requirement.kinerja_file_id === undefined ||
                                          item.tech_requirement.kinerja_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>
                                      
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Surat dukungan dari brand sensor</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.dukungan_brand_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.dukungan_brand_file_id === "" ||
                                          item.tech_requirement.dukungan_brand_file_id === undefined ||
                                          item.tech_requirement.dukungan_brand_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>
                                      
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Surat pernyataan</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <a :href="getBlobsFile(item.tech_requirement.pernyataan_vendor_file_id)" target="blank">
                                      <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                        <span>{{
                                          item.tech_requirement.pernyataan_vendor_file_id === "" ||
                                          item.tech_requirement.pernyataan_vendor_file_id === undefined ||
                                          item.tech_requirement.pernyataan_vendor_file_id === null
                                            ? " Belum ada  File Terupload"
                                            : " File Terupload"
                                        }}</span>
                                      
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <!-- Loggers -->
                              <li class="list-group-item bg-dark text-white mt-5" >
                                <div class="row">
                                  <div class="col-lg-12">
                                    <strong>Logger</strong>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Logger Brand</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.logger.brand }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Logger Model</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.logger.model }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Logger Serial Number</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.logger.sn }}</span>
                                  </div>
                                </div>
                              </li>

                              <li class="list-group-item" >
                                <div class="row">
                                  <div class="col-lg-3">
                                    <strong>Logger MAC Address</strong>
                                  </div>
                                  <div class="col-lg-9">
                                    <span>{{ item.logger.mac}}</span>
                                  </div>
                                </div>
                              </li>

                            

                              <!-- Sensor -->
                                <li class="list-group myshadow mt-5" v-for="(items, idx) in item.logger.sensors" :key="idx" v-if="items.is_active === 1">
                                  <div class="list-group-item bg-dark text-white" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong style="text-transform: uppercase;">SENSOR {{ items.type }}</strong>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item">
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Tipe</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.type }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Brand</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.brand }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Probe</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.probe }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Brosur</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <a :href="getBlobsFile(items.brochure_file_id)" target="blank">
                                          <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                            <span>{{
                                              items.brochure_file_id === "" ||
                                              items.brochure_file_id === undefined ||
                                              items.brochure_file_id === null
                                                ? " Belum ada  File Terupload"
                                                : " File Terupload"
                                            }}</span>   
                                        </a>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Min</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.min }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Max</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.max }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>BMAL Min</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.bmal_min }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>BMAL Max</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.bmal_max }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div v-if="item.type == 'Debit'">
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>BMAL Unit</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.bmal_unit }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Metode Pengukuran</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.bmal_measure }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Jadwal Kalibrasi</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.calibration_schedule }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Tanggal Kalibrasi</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.calibration_date }}</span>
                                      </div>
                                    </div>
                                  </div>
              
                                  <div class="list-group-item" >
                                    <div class="row">
                                      <div class="col-lg-3">
                                        <strong>Metode Kalibrasi</strong>
                                      </div>
                                      <div class="col-lg-9">
                                        <span>{{ items.calibration_method }}</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                            </ul>
                        </div>
                      </div>
                    </transition>
                  </div>
                 

                 

                 

                  <!-- Sensor -->
                 



                      <!-- <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-4">
                            <div @click="toggleAccordion('Logger', item.id, idx)" class="accordion-header" style="cursor:pointer">
                              <button class="btn btn-primary">Logger dan Sensor (klik untuk detail)</button>
                            </div>
                          </div>
                          <div class="col-lg-8" :hidden="isHidden !== idx">
                            <p v-if="listDataCompliencePointsDetails == null">Anda belum mendaftartan Logger</p>
                            <div  class="accordion-content">

                              

                       
                              <div class="col-lg-8">
                            <p v-if="item.station.logger.sensors == null">Anda belum mendaftartan Sensor</p>
                            <div v-if="isOpenSensor" class="accordion-content">
                              <ul class="list-group myshadow  mb-5" v-for="(item, idx) in listDataCompliencePointsSensor" :key="idx">
                                <li class="list-group-item bg-dark text-white" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>SENSOR {{ idx + 1 }}</strong>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Nama Titik Penaatan</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.sensor_type }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Brand</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.brand }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Probe</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.probe }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Brosur</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <a :href="`https://${item.brosur_path}`" target="blank">
                                        <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                          <span>{{
                                            item.brosur_path === "" ||
                                            item.brosur_path === undefined ||
                                            item.brosur_path === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>   
                                      </a>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Min</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.range_min }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Max</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.range_max }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Min</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_min }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Max</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_max }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" v-if="item.sensor_type == 'Debit'">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Unit</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_unit }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Metode Pengukuran</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.metode_pengukuran }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Jadwal Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.jadwal_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Tanggal Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.tanggal_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Metode Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.metode_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Dibuat pada</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ formatDate(item.created_at) }}</span>
                                    </div>
                                  </div>
                                </li>

                              </ul>
                            </div>
                          </div>
                            </div>
                          </div>
                        </div>
                      </li> -->

                      <!-- <li class="list-group-item">
                        <div class="row">
                          <div class="col-lg-3">
                            <div @click="toggleAccordion('Sensor', item.id, idx)" class="accordion-header" style="cursor:pointer">
                              <button class="btn btn-primary">Sensor (klik untuk detail)</button>
                            </div>
                          </div>
                          <div class="col-lg-9" :hidden="isHidden !== idx">
                            <p v-if="listDataCompliencePointsSensor == null">Anda belum mendaftartan Logger</p>
                            <div v-if="isOpenSensor" class="accordion-content">
                              <ul class="list-group myshadow  mb-5" v-for="(item, idx) in listDataCompliencePointsSensor" :key="idx">
                                <li class="list-group-item bg-dark text-white" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>SENSOR {{ idx + 1 }}</strong>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Nama Titik Penaatan</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.sensor_type }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Brand</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.brand }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Probe</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.probe }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Brosur</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <a :href="`https://${item.brosur_path}`" target="blank">
                                        <i class="ni ni-cloud-download-95 text-primary mr-2"></i>
                                          <span>{{
                                            item.brosur_path === "" ||
                                            item.brosur_path === undefined ||
                                            item.brosur_path === null
                                              ? " Belum ada  File Terupload"
                                              : " File Terupload"
                                          }}</span>   
                                      </a>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Min</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.range_min }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Max</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.range_max }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Min</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_min }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Max</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_max }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" v-if="item.sensor_type == 'Debit'">
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>BMAL Unit</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.bmal_unit }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Metode Pengukuran</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.metode_pengukuran }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Jadwal Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.jadwal_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Tanggal Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.tanggal_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Metode Kalibrasi</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ item.metode_kalibrasi }}</span>
                                    </div>
                                  </div>
                                </li>

                                <li class="list-group-item" >
                                  <div class="row">
                                    <div class="col-lg-3">
                                      <strong>Dibuat pada</strong>
                                    </div>
                                    <div class="col-lg-9">
                                      <span>{{ formatDate(item.created_at) }}</span>
                                    </div>
                                  </div>
                                </li>

                              </ul>
                            </div>
                          </div>
                        </div>
                      </li> -->
                  
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </b-container>


       <!-- modal of History Validation -->
       <Modal
        :show="showAnnoncementModalHistory"
        modalContentClasses="announcement-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        @close="showAnnoncementModalHistory = false"
        size="xl">
        <template v-slot:header>
            <div class="modal-title">
                <!-- <p class="mdl-title">Validasi Pendaftaran Sparing</p> -->
            </div>
        </template>
        <div class="px-4 border-top pt-4">
          <div>
            <ValidationHistorys :data="histories" :is-loading-data="isLoadingData" @on-change-tab="getBackData" />
          </div>
        </div>
      </Modal>


      <!-- modal TTE -->
      <Modal
        :show="showAnnoncementModal"
        modalClasses="modal-dialog-scrollable"
        @close="showAnnoncementModal = false"
        size="xl">
        <template v-slot:header>
        </template>
        <div>
          <div id="announcement" class="rounded overflow-hidden mdl-contents">
            <div class="container  d-flex flex-column align-items-center justify-content-center">
              <div  id="printBos" class="card bg-white border-0">
                <div class="card-header px-4" >Tanda Terima Elektronik</div>
                <div
                  class="
                    card-body
                    d-flex
                    flex-column
                    align-items-center
                    justify-content-center
                    p-4
                  "
                >
                  <router-link class="mb-4" to="/">
                    <img src="/img/brand/img-logo-color.png" />
                  </router-link>
                  <h2>KEMENTERIAN LINGKUNGAN HIDUP DAN KEHUTANAN</h2>
                  <div class="card bg-white border-0 w-75 m-5 shadow-none">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th scope="row">Nama Perusahaan</th>
                          <td>{{ tte.comp_name }}</td>
                        </tr>
                        <tr>
                          <th scope="row">Nomor Pendaftaran</th>
                          <td>{{ tte.uid }}</td>
                        </tr>
                        <tr>
                          <th scope="row">Alamat</th>
                          <td>{{ tte.comp_address }}</td>
                        </tr>
                        <tr>
                          <th scope="row">Username</th>
                          <td>{{ tte.user_email }}</td>
                        </tr>
                        <tr>
                          <th scope="row">Password</th>
                          <td>
                            <span class="password-hidden">*******</span>
                            <span class="password-visible" style="display: none;">{{ tte.user_password }}</span>
                            <i class="fas fa-eye ml-2" @click="togglePasswordVisibility"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Status</th>
                          <td>
                            <span class="badge" :class="getStatusClass(tte.status)">{{ (tte.status ? tte.status : "Menunggu Validasi") }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">UID Logger</th>
                          <td>
                            <ul>
                              <!-- Gunakan v-for untuk mengulang setiap UID Logger dalam data -->
                              <li v-for="uid in tte.uid_loggers" :key="uid.uid">{{ uid.spot_name }} - {{ uid.uid }}</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>
                    Dokumen ini sah, diterbitkan secara elektronik melalui SPARING
                    Kementerian Lingkungan Hidup dan Kehutanan sehingga tidak memerlukan
                    cap dan tanda tangan basah. Terima kasih telah melaksanakan
                    pendaftaran SPARING DIREKTORAT PENGENDALIAN PENCEMARAN AIR
                    KEMENTERIAN LINGKUNGAN HIDUP DAN KEHUTANAN
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <div class="row">
            <div class="col text-right">
              <base-button class="bg-primary text-white border-0" @click="downloadPdf">Download</base-button>
              <base-button class="bg-danger border-0" @click="showAnnoncementModal = false">Tutup</base-button>
            </div>
          </div>
        </div>
      </Modal>

      

    </div>
  </template>
  <script>
  import API from "../../../../api/base_url.js"
  import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
  import BaseHeader from "@/components/BaseHeader";
  import moment from 'moment'
  import 'moment-timezone'
  import Modal from "@/components/Modal.vue";
  import ValidationHistorys from "./SparingValidationHistory.vue";
  import html2pdf from "html2pdf.js";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  import baseURL from "@/api/base_url_backend.js";
  
  export default {
    components: {
      BaseHeader,
      RouteBreadCrumb,
      Modal,
      ValidationHistorys
    },
    data() {
      return {
        isHidden: null,
        isOpen: null,
        showAnnoncementModal:false,
        histories: [],
        verifyNote:"",
        isLoadingData: false,
        showAnnoncementModalHistory:false,
        isOpenLogger:false,
        isOpenSensor:false,
        isLoading:true,
        listData: {
          "id": "",
          "total_station": 0,
          "status": 1,
          "created_at": "",
          "permit_no": "",
          "comp_name": "",
          "comp_type_name": "",
          "province_name": "",
          "kabkot_name": "",
          station:[
            {
              logger: {
                sensors:[]
              }
            }
          ]


          
        },
        listDataCompliencePoints: [],
        listDataCompliencePointsDetails: [],
        listDataCompliencePointsDetailsDocument: [],
        listDataCompliencePointsSensor: [],
        tte: {
          uid: "",
          comp_name: "",
          comp_address: "",
          user_email: "",
          user_password: "",
          uid_loggers: []
        }
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`sparing/${this.$route.params.id}` , {headers})
        .then(({data:content}) => {
          this.listData = content.data
          this.isLoading = false
        })
        .catch((err) => {
          console.log(err);
        })
      },
      print() {
        let element = document.getElementById("printBos");
        let opt = {
          margin: 0.5,
          filename: "myfile.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
        }
        // New Promise-based usage:
        html2pdf().set(opt).from(element).save();
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModal = false
      },
      togglePasswordVisibility() {
        var passwordVisible = document.querySelector('.password-visible');
        var passwordHidden = document.querySelector('.password-hidden');

        var icon = event.target;

        if (passwordVisible.style.display === 'none') {
          passwordVisible.style.display = 'inline';
          passwordHidden.style.display = 'none';
          icon.classList.remove('fa-eye-slash');
          icon.classList.add('fa-eye');
        } else {
          passwordVisible.style.display = 'none';
          passwordHidden.style.display = 'inline';
          icon.classList.remove('fa-eye');
          icon.classList.add('fa-eye-slash');
        }
      },
      getStatusClass(status) {
        switch (status) {
          case 'Diterima':
            return 'badge-success';
          case 'Menunggu Validasi':
            return 'badge-warning';
          case 'Ditolak':
            return 'badge-danger';
          case 'Draft':
            return 'badge-info';
          default:
            return 'badge-secondary'; // Warna default jika status tidak sesuai
        }
      },
      downloadPdf() {
        this.print()
      },
      getLocalTime(mydate) {
        let mytime = moment.utc(mydate)
        const localMoment = mytime.local().format("YYYY-MM-DD HH:mm:ss");

        return localMoment.toLocaleString()

      },
      seeTte() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
        let idSparing = this.$route.params.id

        API.get(`sparing/tte/${idSparing}` , {headers})
          .then(({data:content}) => {
            this.tte =  {
                uid: content.data.id,
                comp_name: content.data.comp_name,
                comp_address: content.data.comp_address,
                user_email: content.data.user_email,
                user_password: content.data.user_password,
                uid_loggers: content.data.items
              }
          })
          .catch((err) => {
            console.log(err);
          })



        this.showAnnoncementModal = true
      },
      seeRiwayatValidasi() {
        this.getBackData('validasi')
        this.showAnnoncementModalHistory = true
      },
      async getValidationHistory() {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        // let tmp = await API.get(`companies/sparings/histories?sparing_id=${this.$route.params.id}` , {headers}) 

          let tmp = this.listData.approval_histories

        if (tmp == null) {
          this.histories = []
        } else {
          console.log(tmp,'-->');
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              companyName:tmp[i].user_name,
              adminName:tmp[i].admin_name,
              created_time_user: this.formatDate(tmp[i].registration_time),
              keterangan: tmp[i].keterangan,
              user_id:tmp[i].id,
              created_time: this.formatDate(tmp[i].approval_time),
              status:tmp[i].status
            })
          }
        }

        return  this.histories
        
      },
      
      async getChangesData() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        // let tmp = await API.get(`companies/sparings/fields/histories?sparing_id=${this.$route.params.id}` , {headers}) 

        let tmp = this.listData.field_histories

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.length; i++) {
            this.histories.push({
              id:i+1,
              // no:1,
              name:tmp[i].field_name,
              updatedAt:this.formatDate(tmp[i].created_at),
              oldData:tmp[i].old_value,
              newData:tmp[i].new_value,
            })
          }
        }

        return  this.histories
      },

      async getChangesDocument() {

      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        // let tmp = await API.get(`companies/sparings/docs/histories?sparing_id=${this.$route.params.id}` , {headers}) 

        let tmp = null

        if (tmp == null) {
          this.histories = []
        } else {
          for (let i = 0; i < tmp.data.data.length; i++) {
            this.histories.push({
              no: 1,
              documentName: tmp.data.data[i].doc_name,
              documentType: 'doc.tipe_dokumen',
              updatedAt: this.formatDate(tmp.data.data[i].created_at),
              oldDocument: tmp.data.data[i].old_path,
              newDocument: tmp.data.data[i].new_path,
            })
          }
        }

        return  this.histories
      },
      
      getBackData(type) {
        if (type === 'validasi') {
            this.histories = []

            setTimeout(() => {
              this.getValidationHistory()
            }, 100);
        }

        if (type === 'perubahanData') {
            this.histories = []

            setTimeout(() => {
              this.getChangesData()
            }, 100);
        }

        if (type === 'perubahanDokumen') {
            this.histories = []

            setTimeout(() => {
              this.getChangesDocument()
            }, 100);
        }
      },

      getBlobsFile(filename) {
        let url = `${baseURL}blobs/download/${filename}`
        return url
      },
      getStatus(val) {
        if (val == 1) {
          return "Disetujui"
        } else if (val == 2) {
          return "Di Tolak"
        }
      },
      toggleAccordion(type, id, index) {
        // this.getDataTitikPenaatanDetail(id)
        // if (type == 'Logger') {
        //   this.isOpenLogger = !this.isOpenLogger
        //   this.isOpenSensor = !this.isOpenSensor
        // } 

        // if (this.isHidden == null) {
        //   this.isHidden = index
        // } else {
        //   this.isHidden = null
        // }
        this.isOpen = this.isOpen === index ? null : index;
      },
      isOpenAccordion(index){
        return this.isOpen === index;
      },
      
      formatDate(timestamp) {
      if (timestamp == 0) {
        return "-"
      }

      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY');
      return humanDate;
      },
      back() {
        this.$router.push("/sparing");
      },
    },
  };
  </script>
  <style scoped>
.myshadow {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}
.mdl-contents {
  overflow-y: auto;
  max-height: 90%;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #FFFFFF;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.announcement-modal-backgrounds {
  background: #ff9999;
}
.slide-enter-active, .slide-leave-active {
  transition: max-height 0.5s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  overflow: hidden;
}
  </style>
  